import {Component} from 'preact';
import style from './style.module.scss';
import Picker from '../../components/picker';
import TextInput from '../../components/textInput';
import Button from '../../components/button';
import {customerService} from '../../services/customerService';
import {route} from 'preact-router';
import Map from '../../components/map';
import Header from '../../components/header';
import classNames from 'classnames';
import {i18nService} from "../../services/i18nService";
import Flatpickr from "../../components/flatpickr";

export default class AdditionalUserData extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sizes: [
        {value: 'N/A-SIZE'},
        {value: 'XS'},
        {value: 'S'},
        {value: 'M'},
        {value: 'L'},
        {value: 'XL'},
        {value: 'XXL'},
      ],
      sexes: [
        {value: 'N_A'},
        {value: 'MALE'},
        {value: 'FEMALE'},
      ],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    customerService._subscribeForUser((user) => {
      if (user && user.profile &&
          user.profile.address &&
          user.profile.address.postalCode) {
        this.setState(
            {postalCode: user.profile.address.postalCode});
      }
    });

    let streetText = await i18nService.translate('cp_placeholder_street');
    let postalText = await i18nService.translate('cp_placeholder_postal_code');
    let cityText = await i18nService.translate('cp_placeholder_city');
    let birthdayText = await i18nService.translate('cp_placeholder_birthday');

    try {
      this.setState({streetText: streetText});
      this.setState({postalText: postalText});
      this.setState({cityText: cityText});
      this.setState({birthdayText: birthdayText});
    } catch (_) {
    }
  }

  render(props, state) {
    return (
        <div className={style['additional-data']}>
          <Header/>
          <div className={classNames('container', style.container)}>
            <div class={style["info-text"]}>{i18nService.translate('cprd_text_talk_about_you')}</div>

            <form id={'additionalDataForm'} onSubmit={this.handleSubmit} autocomplete={'off'}>
              <div className={style.flex}>
                <div class={style['your-store']}>
                  <Map storeCallback={(store) => {
                    this.setState({store: store});
                  }}/>
                </div>

                <div className={style.divider}/>

                <div className={style["about-you"]}>

                  <Flatpickr
                    inputCallback={(date) => {
                    this.setState({birthday: date});
                  }}>
                    <TextInput
                      name={'additional-data-birthday'}
                      placeholder={state.birthdayText}
                      grey={true}
                    />
                  </Flatpickr>

                  <Picker
                    options={state.sizes.map((size) => {
                      return {
                        name: i18nService.translate(size.value),
                        value: size.value,
                      };
                    })}
                    label={i18nService.translate('cp_sizes_label')}
                    inputCallback={(option) => {
                      this.setState({size: option.value});
                    }}
                  />

                  <Picker
                    options={state.sexes.map((sex) => {
                      return {
                        name: i18nService.translate(sex.value),
                        value: sex.value,
                      };
                    })}
                    label={i18nService.translate('cp_sexes_label')}
                    inputCallback={(option) => {
                      this.setState({sex: option.value});
                    }}
                  />

                  <TextInput
                  name={'additional-data-street'}
                  placeholder={state.streetText}
                  grey={true}
                  inputCallback={(input) => {
                    this.setState({street: input});
                  }}
                  autocomplete={'street-address'}
                  />

                  <TextInput
                  type={'numbers'}
                  maxlength={5}
                  name={'additional-data-postal'}
                  placeholder={state.postalText}
                  value={state.postalCode}
                  grey={true}
                  inputCallback={(input) => {
                    this.setState({postalCode: input});
                  }}
                  autocomplete={'postal-code'}
                  />

                  <TextInput
                  name={'additional-data-city'}
                  placeholder={state.cityText}
                  grey={true}
                  inputCallback={(input) => {
                    this.setState({city: input});
                  }}
                  autocomplete={'address-level2'}
                  />

                  <div className={style.right}>
                    <div class={style["button-continue"]}>
                      <Button form='additionalDataForm'
                              label={i18nService.translate('cprd_button_finish')}
                      />
                    </div>
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>
    );
  }

  async handleSubmit(event) {
    event.preventDefault();

    const profile = {
      address: {}
    };

    if (this.state.sex) {
      profile.gender = this.state.sex;
    }

    if (this.state.size) {
      profile.size = this.state.size;
    }

    if (this.state.store) {
      profile.storeId = this.state.store.id;
    }

    if (this.state.birthday) {
      profile.birthday = this.state.birthday;
    }

    if (this.state.city) {
      profile.address.city = this.state.city;
    }

    if (this.state.postalCode) {
      profile.address.postalCode = this.state.postalCode;
    }

    if (this.state.street) {
      profile.address.street = this.state.street;
    }

    if (this.state.sex || this.state.size || this.state.store || this.state.birthday || this.state.city || this.state.postalCode || this.state.street) {
      await customerService.updateProfile(profile);
    }

    route('/dashboard');
  }
}