import {Component} from 'preact';
import style from './style.module.scss';
import LogoWhite from '../../assets/images/get_more_logo_white.png';
import LogoBlack from '../../assets/images/get_more_logo_black.png';
import ImgLoginRegister from '../../assets/images/landing/login_register.jpg';
import ImgInfoClubOffers from '../../assets/images/landing/landing_page_club_offers.jpg';
import ImgInfoCooperation from '../../assets/images/landing/landing_page_cooperation.jpg';
import ImgInfoPoints from '../../assets/images/landing/landing_page_points.jpg';
import VideoOverlay from '../../assets/images/video-overlay.jpg';
import ImgInfoMembership from '../../assets/images/landing/landing_page_membership.jpg';
import InfoItem from '../../components/infoItem';
import {route} from 'preact-router';
import Checkbox from '../../components/checkbox';
import {i18nService} from '../../services/i18nService';
import Button from '../../components/button';
import {customerService} from '../../services/customerService';
import ErrorMessage from '../../components/errorMessage';
import {shareIdService} from '../../services/shareIdService';

export default class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: false,
      infoItems: [],
    };

    if (props.shareId) {
      shareIdService.setShareId(props.shareId);
    }

    this.goToLogin = this.goToLogin.bind(this);
    this.goToRegister = this.goToRegister.bind(this);
    this.handleActivateLoyalty = this.handleActivateLoyalty.bind(this);
  }

  async componentDidMount() {
    this.setState({infoItems: [
      {
        url: ImgInfoMembership,
        headline: i18nService.translate('home_join_club_headline'),
        text: i18nService.translate('home_join_club_text')
      },
      {
        url: ImgInfoPoints,
        headline: i18nService.translate('home_collect_points_headline'),
        text: i18nService.translate('home_collect_points_text'),
      },
      {
        url: ImgInfoClubOffers,
        headline: i18nService.translate('home_club_rewards_headline'),
        text: i18nService.translate('home_club_rewards_text'),
      },
      {
        url: ImgInfoCooperation,
        headline: i18nService.translate('home_cooperation_headline'),
        text: i18nService.translate('home_cooperation_text'),
      }
    ]});
  }

  render(props, state) {
    return (
        <div className={style.fullwidth}>
          <div class='container'>
            <div class={style.home}>

                {this.renderActions(props, state)}

                <div class={style.flex}>
                <div class={style.column}>
                  {this.renderInfoItem(state.infoItems[0])}
                </div>
                <div class={style.space}/>
                <div class={style.column}>
                  {this.renderInfoItem(state.infoItems[1])}
                </div>
              </div>
              <div class={style.flex}>
                <div class={style.column}>
                  {this.renderInfoItem(state.infoItems[2])}
                </div>
                <div class={style.space}/>
                <div class={style.column}>
                  {this.renderInfoItem(state.infoItems[3])}
                </div>
              </div>

              <div class={style.flex}>
                <div class={style.column}>
                  <div class={style["ny-yt-player"]}>
                    <cookie-overlay cookie={'NY_COOKIE_YOUTUBE'} placeholder={VideoOverlay}>
                      <template dangerouslySetInnerHTML={{ __html:"<youtube-player url=\"https://www.youtube-nocookie.com/embed/srj35UHaZCY?rel=0&amp;showinfo=0\"></youtube-player>" }}/>
                    </cookie-overlay>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
    );
  }

  renderInfoItem(infoItem) {
    return (
        <InfoItem infoData={infoItem}/>
    );
  }

  goToLogin() {
    route('/login');
  }

  goToRegister() {
    route('/register');
  }

  async handleActivateLoyalty() {

    const response = await customerService.acceptLoyalty();
    if (response.error && response.error.error) {
      this.setState({errorMessage: response.error.error});
    } else {
      route('/dashboard');
    }

  }

  renderActions(props, state) {
    if (props.isAuthenticated && !props.hasLoyalty) {

      return (
        <div class={style["action-container"]}>
          <img class={style['background-image']} src={ImgLoginRegister} alt={'Login Background'} />
          <div className={style.overlay}/>
          <div class={style.wrapper}>
            <div class={style["action-content"]}>
              <img class={style["logo-white"]} src={LogoWhite} alt={'White Get More Logo'}/>
              <img class={style["logo-black"]} src={LogoBlack} alt={'Black Get More Logo'}/>
              <div class={style.infoText}>{i18nService.translate('home_welcome_text')}</div>

              <div class={style["checkbox-container"]}>
                <div class={style.checkbox}>
                  <Checkbox
                    label={i18nService.translate('loyalty_checkbox_terms_text', '/legal/terms-and-conditions/')}
                    required={true}
                    name={'privacy'}
                    unsafeLabel={true}
                    inputCallback={() => {
                      this.setState((
                        prevState => (
                          {termsChecked: !prevState.termsChecked})));
                    }}/>
                </div>

                <div class={style["mobile-only"]}>
                  <Checkbox
                    darkStyle={true}
                    label={i18nService.translate('loyalty_checkbox_terms_text', '/legal/terms-and-conditions/')}
                    required={true}
                    name={'privacy'}
                    unsafeLabel={true}
                    inputCallback={() => {
                      this.setState((
                        prevState => (
                          {termsChecked: !prevState.termsChecked})));
                    }}/>
                </div>

                <div class={style.privacy} dangerouslySetInnerHTML={{__html: i18nService.translate('loyalty_checkbox_privacy_text', '/legal/privacy/')}}/>
              </div>


              <div className={style['button-wrapper']}>
                <Button
                  disabled={!state.termsChecked || !state.termsChecked}
                  label={i18nService.translate('loyalty_accepttos_button')}
                  onClickCallback={this.handleActivateLoyalty}/>
              </div>
              <div class={style['required-info-container']}>
                <div class={style['required-info']}>{i18nService.translate('checkbox_required_hint')}</div>
              </div>
            </div>
          </div>

          <ErrorMessage message={state.errorMessage}/>
        </div>
      );

    } else {
        return (

            <div class={style["action-container"]}>
                <img class={style['background-image']} src={ImgLoginRegister} alt={'Login Background'} />
                <div className={style.overlay}/>
                <div class={style["action-content"]}>
                      <img class={style["logo-white"]} src={LogoWhite} alt={'White Get More Logo'}/>
                      <img class={style["logo-black"]} src={LogoBlack} alt={'Black Get More Logo'}/>
                        <div class={style.infoText}>{i18nService.translate('home_welcome_text')}</div>
                      <div className={style['button-wrapper']}>
                          <Button black={false} onClickCallback={this.goToLogin}
                                  label={i18nService.translate('cp_button_login')}/>

                          <div className={style['button-divider']}/>
                          <Button black={false} onClickCallback={this.goToRegister} label={i18nService.translate('cpru_button_register')}/>
                      </div>
                  </div>
            </div>
        );
    }
  }
}