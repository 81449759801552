import {Component} from 'preact';
import style from './style.module.scss';
import TextInput from '../../components/textInput';
import Button from '../../components/button';
import Checkbox from '../../components/checkbox';
import {customerService} from '../../services/customerService';
import {route} from 'preact-router';
import {i18nService} from '../../services/i18nService';
import ErrorMessage from '../../components/errorMessage';
import {countryService} from '../../services/countryService';
import Header from '../../components/header';
import {shareIdService} from '../../services/shareIdService';
import classNames from "classnames";
import {
  RegistrationFailedEvent,
  RegistrationSuccessEvent,
  TrackerService,
} from "../../services/trackingService";
import CircularProgress from "../../components/circularProgress";

export default class Register extends Component {

  constructor(props) {
    super(props);
    this.state = {registerButtonActive: true, countries: []};

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const countries = await countryService.getCountries();
    let selectedCountry = null;
    for (let c of countries) {
      if (c.iso_3166.toLowerCase() === 'de') {
        selectedCountry = c;
        break;
      }
    }

    let shareId = shareIdService.getShareId();


    this.setState({
      countries: countries,
      selectedCountry: selectedCountry,
      shareId: shareId,
      showIndicator: false
    });
  }

  render(props, state) {
    return (
        <div class={style['register-container']}>
          <Header/>
          <div class={style.container}>
            <div
                class={state.errorMessage ? style.hide : style['info-text']}>{i18nService.translate('register_advertising_text')}</div>
            <div class={style['error-wrapper']}>
              {state.errorMessage &&
                  <div class={style["exclamation-point"]}>
                    {this._renderExclamationPoint()}
                  </div>}
              {state.errorMessage &&
                  <div class={style['error-message']}>
                    <ErrorMessage message={state.errorMessage}/>
                    <Button label={i18nService.translate('cpwi_reset_password')}
                            onClickCallback={this.handlePasswordReset}
                            textStyle={true}
                    />
                  </div>}
            </div>
            <form id='register_form' onSubmit={this.handleSubmit} autocomplete={'off'}>
              <div className={style['flex-container']}>
                <div className={style.column}>

                  <TextInput type={'email'}
                             name={'register-email'}
                             placeholder={i18nService.translate('cp_placeholder_email')}
                             grey={true}
                             required={true}
                             inputCallback={(input) => {
                               if (input)
                                 this.setState({email: input});
                               else
                                 this.setState({email: ''})
                             }}
                             autocomplete={'email'}
                  />

                  <TextInput type={'password'}
                             name={'register-password'}
                             grey={true}
                             required={true}
                             minlength={8}
                             placeholder={i18nService.translate('cp_placeholder_password')}
                             inputCallback={(input) => {
                               if (input)
                                 this.setState({password: input});
                               else
                                 this.setState({password: ''})
                             }}
                             autocomplete={'new-password'}
                  />

                  <TextInput type={'password'}
                             name={'register-password-new'}
                             grey={true}
                             required={true}
                             minlength={8}
                             placeholder={i18nService.translate('cp_placeholder_repeat_pw')}
                             inputCallback={(input) => {
                               if (input)
                                 this.setState({passwordRepeat: input});
                               else
                                 this.setState({passwordRepeat: ''})
                             }}
                             errorMessage={this.getPasswordRepeatErrorMessage(state)}
                             autocomplete={'new-password'}
                  />

                  <TextInput placeholder={i18nService.translate('cp_placeholder_firstname')}
                             name={'register-first-name'}
                             grey={true}
                             required={true}
                             inputCallback={(input) => {
                               if (input)
                                 this.setState({firstName: input});
                               else
                                 this.setState({firstName: ''})
                             }}
                             autocomplete={'given-name'}
                  />

                  <TextInput name={'register-last-name'}
                             placeholder={i18nService.translate('cp_placeholder_lastname')}
                             grey={true}
                             required={true}
                             inputCallback={(input) => {
                               if (input)
                                 this.setState({lastName: input});
                               else
                                 this.setState({lastName: ''})
                             }}
                             autocomplete={'family-name'}
                  />

                  <div class={style["required-info-container"]}>
                    <div class={style["required-info"]}>{i18nService.translate('checkbox_required_hint')}</div>
                  </div>
                </div>

                <div class={style.spacer}/>

                <div class={style.column}>

                  <TextInput type={'numbers'}
                             maxlength={5}
                             name={'register-postal-code'}
                             placeholder={i18nService.translate('cp_placeholder_postal_code')}
                             grey={true}
                             inputCallback={(input) => {
                               this.setState({postalCode: input});
                             }}
                             autocomplete={'postal-code'}
                  />

                  <TextInput name={'register-share-id'}
                             placeholder={i18nService.translate('get_more_placeholder_share_id')}
                             grey={true}
                             required={false}
                             value={state.shareId}
                             inputCallback={(input) => {
                               shareIdService.setShareId(input);
                               this.setState({shareId: input});
                             }}
                  />
                  {state.selectedCountry && <TextInput placeholder={'Land'}
                                                       name={'register-country'}
                                                       disabled={true}
                                                       grey={true}
                                                       value={state.selectedCountry &&
                                                           state.selectedCountry.localized_name}
                  />}

                  <div className={style["flex-container"]}>
                    <div className={style['legals-container']}>
                      <Checkbox
                          darkStyle={true}
                          label={i18nService.translate('loyalty_checkbox_terms_text', {
                            isHtml: false,
                            args: ['/legal/terms-and-conditions/']
                          })}
                          required={true}
                          name={'terms'}
                          unsafeLabel={true}
                          inputCallback={() => {
                            this.setState((
                                prevState => ({termsChecked: !prevState.termsChecked})));
                          }}/>
                      <Checkbox
                          darkStyle={true}
                          label={<span dangerouslySetInnerHTML={{
                            __html: i18nService.translate('loyalty_checkbox_newsletter_text', {
                              isHtml: false,
                              args: ['/legal/privacy/#newsletter']
                            },)
                          }}></span>}
                          required={false}
                          name={'newsletter'}
                          inputCallback={() => {
                            this.setState((
                                prevState => ({newsletterChecked: !prevState.newsletterChecked})));
                          }}/>

                      <div class={style.privacy} dangerouslySetInnerHTML={{
                        __html: i18nService.translate('loyalty_checkbox_privacy_text', {
                          isHtml: false,
                          args: ['/legal/privacy/#customer-profil-get-more-club']
                        })
                      }}/>

                      <div className={style.spacer}/>
                    </div>
                  </div>
                  <div className={style['register-button-wrapper']}>
                    <div className={style.spacer}/>
                    <div class={style["button-login"]}>
                      <Button label={i18nService.translate('cp_button_login')}
                              onClickCallback={() => route('/login')}
                              textStyle={true}/>
                    </div>

                    <div class={style["button-register"]}>
                      <Button form='register_form'
                              grey={true}
                          // TODO: Error message needs to be added when button invalid
                              invalid={!this.validate()}
                              label={i18nService.translate('cpru_button_register')}
                              onClickCallback={this.handleSubmit}
                              loading={this.state.showIndicator}/>
                    </div>
                  </div>

                  <div class={style["mobile-only"]}>
                    <div class={style["required-info-container"]}>
                      <div class={style["required-info"]}>{i18nService.translate('checkbox_required_hint')}</div>
                    </div>
                  </div>

                </div>
              </div>
            </form>
          </div>
        </div>
    );
  }

  _renderExclamationPoint() {
    return (
        <span aria-hidden="true"
              class={classNames(style.icon, style.exclamation, style.solid)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
              d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/>
        </svg>
      </span>
    );
  }

  getPasswordRepeatErrorMessage(state) {

    if (state.password && state.passwordRepeat && state.password !==
        state.passwordRepeat) {
      return i18nService.translate('register_passwords_not_match_text')
    }

    return false;
  }

  async handleSubmit(event) {
    event.preventDefault();
    const valid = this.validate();
    if (valid) {
      this.setState({registerButtonActive: false, showIndicator: true});
      try {
        await customerService.registerCustomer(
            this.state.email,
            this.state.password,
            this.state.firstName,
            this.state.lastName,
            this.state.selectedCountry.iso_3166,
            this.state.postalCode,
            this.state.newsletterChecked, // optInEmailAdvertisementConsent
            this.state.newsletterChecked, // optInEmailChannelLoyalty
            this.state.newsletterChecked,  // optInEmailChannelNewsletter
        );
        this.setState({showIndicator: false})
        TrackerService.trackClick(RegistrationSuccessEvent);
        customerService.setFirstVisit(true);
        route('/additionalData');
      } catch (customerServiceError) {
        TrackerService.trackClick(RegistrationFailedEvent);
        this.setState({errorMessage: i18nService.translate(`registration_${customerServiceError.error.toLowerCase()}`)});
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }

    } else {
      this.setState({errorMessage: 'input is not valid'});
    }
    this.setState({registerButtonActive: true});
  }

  validate() {
    try {
      return this.state.email &&
          this.state.password &&
          this.state.passwordRepeat &&
          this.state.password === this.state.passwordRepeat &&
          this.state.firstName && this.state.firstName.length > 0 &&
          this.state.lastName && this.state.lastName.length > 0 &&
          this.state.selectedCountry && this.state.selectedCountry.iso_3166 &&
          this.state.termsChecked;

    } catch (e) {
      return false;
    }
  }

  handlePasswordReset() {
    location.assign(
        '/customer-profile/#/public/customer-profile/reset-password');
  }
}